<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
              size="mini"
            >
              <el-form-item label="市政道路名称："  style="margin-bottom: 0px">
                {{form.roadName}}
              </el-form-item>
              <el-form-item label="所属标段：" >
                <el-cascader
                  v-model="form.bidId"
                  placeholder="未选择"
                  :options="bidList"
                  :show-all-levels="false"
                  @change="handleBidChage"
                  filterable
                  disabled
                ></el-cascader>
              </el-form-item>
              <el-form-item label="道路类别：" >
                <el-select v-model="form.roadType" placeholder="未选择" filterable disabled>
                  <el-option
                    v-for="(item, idx) in $enums_hw.roadType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="城中村：">
                <el-select v-model="form.villageId" placeholder="未选择" filterable disabled>
                  <el-option
                    v-for="(item, idx) in municipalRoadData.data"
                    :key="idx"
                    :label="item.villageName"
                    :value="item.villageId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="主/次干道：">
                <el-select v-model="form.roadLevel" placeholder="未选择" filterable disabled>
                  <el-option
                    v-for="(item, idx) in $enums_hw.roadLevel.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="保洁等级：">
                <el-select v-model="form.cleanLevel" placeholder="未选择" filterable disabled>
                  <el-option
                    v-for="(item, idx) in $enums_hw.cleanLevel.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="是否进行机扫：">
                <el-checkbox v-model="form.isSweeper" disabled>是</el-checkbox>
              </el-form-item>
              <el-form-item label="是否进行清洗：">
                <el-checkbox v-model="form.isWasher" disabled>是</el-checkbox>
              </el-form-item>
              <el-form-item label="起止位置：" class="startStopPosition">
                {{form.startStopPosition}}
              </el-form-item>
              <el-form-item label="是否5333严管区道路：">
                <el-checkbox v-model="form.isDistrictZone" disabled>是</el-checkbox>
              </el-form-item>
              <el-form-item label="是否商业街：">
                <el-checkbox v-model="form.isBusinessStreet" disabled>是</el-checkbox>
              </el-form-item>
              <el-form-item label="主车道长度(m)：">
                {{form.mainRoadLen}}
              </el-form-item>
              <el-form-item label="辅道长度(m)：">
                {{form.sideRoadLen}}
              </el-form-item>
              <el-form-item label="主车道宽度(m)：">
                {{form.mainRoadWide}}
              </el-form-item>
              <el-form-item label="辅道宽度(m)：">
                {{form.sideRoadWide}}
              </el-form-item>
              <el-form-item label="主车道面积(m²)：">
                {{form.mainRoadArea}}
              </el-form-item>
              <el-form-item label="辅道面积(m²)：">
                {{form.sideRoadArea}}
              </el-form-item>
              <el-form-item label="人行道长度(m)：">
                {{form.sideWalkLen}}
              </el-form-item>
              <el-form-item label="绿化带长度(m)：">
                {{form.greenBeltsLen}}
              </el-form-item>
              <el-form-item label="人行道宽度(m)：">
                {{form.sideWalkWide}}
              </el-form-item>
              <el-form-item label="绿化道宽度(m)：">
                {{form.greenBeltsWide}}
              </el-form-item>
              <el-form-item label="人行道面积(m²)：">
                {{form.sideWalkArea}}
              </el-form-item>
              <el-form-item label="绿化带面积(m²)：">
                {{form.greenBeltsArea}}
              </el-form-item>
              <el-form-item label="门店门前面积(m²)：">
                {{form.storeFrontArea}}
              </el-form-item>
              <el-form-item label="各面积小计(m²)：">
                {{form.areaTotal}}
              </el-form-item>
              <el-form-item label="绿化带数：">
                {{form.greenBeltsNum}}
              </el-form-item>
              <el-form-item label="护栏数(个)：">
                {{form.guardrailNum}}
              </el-form-item>
              <el-form-item label="路段配备打扫人员：">
                {{form.roadSweepNum}}
              </el-form-item>
              <el-form-item label="路段配备保洁人员：">
                {{form.roadCleanNum}}
              </el-form-item>
              <el-form-item label="蓝色果皮箱(个)：">
                {{form.blueFruitTrunk}}
              </el-form-item>
              <el-form-item label="240L密闭式垃圾桶(个)：">
                {{form.closedBin240l}}
              </el-form-item>
              <el-form-item label="果皮咖啡色(个)：">
                {{form.brownFruitTrunk}}
              </el-form-item>
              <el-form-item label="660L密闭式垃圾桶(个)：">
                {{form.closedBin660l}}
              </el-form-item>
              <el-form-item label="工具房(个)：">
                {{form.toolRoom}}
              </el-form-item>
              <el-form-item label="垃圾房(个)：">
                {{form.garbageRoom}}
              </el-form-item>
              <el-form-item label="路段配备人员总数(个)：">
                {{form.roadDemandNum}}
              </el-form-item>
              <el-form-item label="车道数量(条)：">
                {{form.laneNum}}
              </el-form-item>

              <!-- 垃圾压缩箱 -->
              <el-form-item label="特殊道路管理："></el-form-item>
              <div class="add-item" v-for="(tab,index) in form.cityRoadSpecialList" :key="index">
                <div style="text-align:right;min-height:20px">
                  <span
                    v-if="index!=0"
                    style="display:inline-block;padding: 10px 15px;width:40px;cursor:pointer"
                    @click="onDeleteTab(tab)"
                  >
                    <i class="el-icon-close"></i>
                  </span>
                </div>
                <div class="add-item-main">
                  <el-form-item label="道路名称：" >
                    {{tab.specialRoadName}}
                  </el-form-item>
                  <el-form-item label="道路类型：">
                    <el-select v-model="tab.specialRoadType" placeholder="未选择" filterable disabled>
                      <el-option
                        v-for="(item, idx) in $enums_hw.specialRoadType.list"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="面积(m²)：">
                    {{tab.area}}
                  </el-form-item>
                  <!-- <el-form-item label="划线："></el-form-item> -->
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <ayl-map-polygon v-model="mapData"></ayl-map-polygon>
      </div>

      <div class="tac mt40px">
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [{ name: "市政道路详情" }],
      //地图数据
      mapData: {
        polygonScope: null, //（1个）多边形具体的点，形如           [[118,36],[117,32],[116,113]]
        scope: null //（1个）围栏范围，经纬度点阵数组，形如  [[118,36],[117,32],[116,113]]
      },
      submitLoading: false,
      bidList: [],
      municipalRoadData: [], //城中村
      form: {},
      rules: {
        roadName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: `请输入1-20位市政道路名称`,
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: {
          required: true,
          message: "请选择所属标段",
          trigger: "change"
        },
        roadType: {
          required: true,
          message: "请选择道路类别",
          trigger: "change"
        }
      },
      cityRoadSpecialListRules: {
        specialRoadName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: `请输入1-20位道路名称`,
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ]
      }
    };
  },
  methods: {
    async handleBidChage(value) {
      this.form.bidId = value[1]; //所属标段
      this.form.villageId = null;
      this.municipalRoadData = [];
      //获取城中村列表
      this.municipalRoadData = await this.$api_hw.urbanVillagesManagement_listVillage({
        bidId: value[1]
      });

      let self = this;
      self.form.bidId = value[1]; //所属标段
      if (self.mapData && self.form.bidId !== "") {
        self.mapData.move2center = true;
        self.$api_hw
          .common_getBidInfo({
            id: self.form.bidId
          })
          .then(res => {
            self.log("mk", res.bidCoordinateScope);
            self.mapData.scope = res.bidCoordinateScope;
          })
          .catch(() => {
            self.mapData.scope = null;
          });
      }
    },

    //异步提交表单
    async submit() {
      let _this = this;

      await _this.$api_hw.municipalRoad_update({
        sanitationCityRoadVO: _fixData(self.form)
      });

      function _fixData(d) {
        for (let key in d) {
          switch (key) {
            case "isSweeper":
            case "isWasher":
            case "isDistrictZone":
            case "isBusinessStreet":
              d[key] = d[key] * 1;
              break;
            case "roadCoordinateScope":
              if (self.mapData.polygonScope) {
                d[key] = JSON.stringify(self.mapData.polygonScope);
                d.isDrawn = 1;
              }else{
                d[key] = "";
                d.isDrawn = 0;
              }
              break;
          }
        }
        return d;
      }
    },

    async onSubmit() {
      var _this = this;

      if (_this.submitLoading) return; //防止重复提交

      _this.$refs.form.validate(async valid => {
        if (!valid) return;
        _this.submitLoading = true;
        try {
          await _this.submit();
          _this.$notify({
            title: "成功",
            message: `${_this.nav[0].name}成功`,
            type: "success"
          });

          BUS.$emit(
            BUSEVENT.REFRESH_MUNICIPAL_ROAD_MANAGEMENT,
            _this.$route.query.id
          );
          _this.onClose();
          _this.$router.push(
            "/district-management/municipal-road/municipal-road-management"
          );
        } catch (e) {
          // console.error(e);
        } finally {
          _this.submitLoading = false;
        }
      });
    },
    onClose() {
      BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
    },
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes() {
      return this.$store.state.permission.routes;
    }
  },
  async mounted() {
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
  },
  async activated() {
    let self = this;
    //详情数据
    let formData = await this.$api_hw.municipalRoad_getById({
      id: this.$route.query.id
    });

    //获取城中村列表
    self.municipalRoadData = await this.$api_hw.urbanVillagesManagement_listVillage({
      bidId: formData.bidId
    });

    this.form = _fixData(formData);

    

    /**
     * 把服务器端的Data 修改一下以方便编辑
     */
    function _fixData(d) {
      for (let key in d) {
        switch (key) {
          case "isSweeper":
          case "isWasher":
          case "isDistrictZone":
          case "isBusinessStreet":
            d[key] = !!d[key];
            break;
          case "roadCoordinateScope":
            try {
              self.mapData.polygonScope = JSON.parse(d[key]);
            } catch (e) {
              self.mapData.polygonScope = null;
            }
            break;
          case "bidScope":
              try {
                self.mapData.move2center = true;
                self.mapData.scope = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.scope = null;
              }
              break;
        }
      }
      return d;
    }
  },
  deactivated() {}
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

.add-item
  width: 100%
  // height: 220px
  border: 1px solid rgba(225,225,225,1)
  border-radius: 2px
  margin-bottom: 20px
  .add-item-main
    margin: 10px 0

.add-btn
  margin: 20px 0
  text-align: center
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
</style>
<style>
.startStopPosition {
  width: 100% !important;
}
.startStopPosition .el-input__inner {
  width: 320px;
}
</style>